import React from 'react';

// Styles
import { StandardButtonSmallStyles } from './StandardButtonSmall.styles';

const StandardButtonSmall = ({
  value,
  onClick,
  name,
}: {
  value: string;
  onClick: () => void;
  name: string;
}): JSX.Element => {
  return (
    <StandardButtonSmallStyles type="button" onClick={onClick} name={name}>
      {value}
    </StandardButtonSmallStyles>
  );
};

export default StandardButtonSmall;
