export const constants: {
  welcomeText: string;
  activationCodeGraphicAltText: string;
  activationCodeFormMainText: string[];
  activationCodePlaceholderText: string;
  activationCodeRequiredFieldError: string;
  activationCodeLengthError: string;
  activationCodeRegisteredText: string;
  continueButtonText: string;
  dateFormatErrorText: string;
  hourIsRequiredText: string;
  hourRangeError: string;
  minuteIsRequiredText: string;
  minuteRangeError: string;
  logCollectionTimeNowButtonText: string;
  toggleManualCollectionText: string;
  submitButtonText: string;
  readyToCollectText: string;
  alreadyCollectedText: string;
  hourCollectionText: string;
  minuteCollectionText: string;
  amPmCollectionText: string;
  checkmarkAriaLabelText: string;
  enterCollectionText: string;
  collectionTimeSubmittedText: string;
  thankYouText: string;
  thankYouInstructionsLineOne: string;
  thankYouInstructionsLineTwo: string;
} = {
  // Generl form text
  welcomeText: 'Welcome to Tasso!',

  // Activation Code form text
  activationCodeFormMainText: [
    'Before beginning your sample collection',
    'please enter the Activation Code from',
    'your Tasso Device kit',
  ],
  activationCodePlaceholderText: 'Enter Activation Code',
  activationCodeRequiredFieldError: 'This is a required field.',
  activationCodeLengthError: 'Activation Code must be less than 65 characters.',
  continueButtonText: 'Continue',
  activationCodeGraphicAltText: 'Tasso activation code graphic',
  activationCodeRegisteredText: 'Activation Code Registered Successfully',

  // Collection form text
  dateFormatErrorText: 'Date format must be YYYY-MM-DD HH:MM',
  hourIsRequiredText: 'Hour of collection is required.',
  hourRangeError: 'Hour must be between 1 and 12.',
  minuteIsRequiredText: 'Minute of collection is required.',
  minuteRangeError: 'Minute must be between 0 and 59.',
  logCollectionTimeNowButtonText: 'Log collection time now',
  toggleManualCollectionText: 'Document time of your collection',
  submitButtonText: 'Submit',
  readyToCollectText: 'Ready to start your collection?',
  hourCollectionText: 'Enter hour of collection.',
  minuteCollectionText: 'Enter minute of collection.',
  amPmCollectionText: 'Select am or pm for collection.',
  checkmarkAriaLabelText: 'green checkmark emoji',
  alreadyCollectedText: 'Already collected?',
  enterCollectionText: 'Enter collection time:',
  collectionTimeSubmittedText: 'Collection Time Submitted Successfully',

  // Thank you form text
  thankYouText: 'Thank You!',
  thankYouInstructionsLineOne: 'Please follow the instructions in your kit',
  thankYouInstructionsLineTwo: 'to complete your sample collection.',
};
