import React from 'react';

// Styles
import { ProgressBarStyles } from './ProgressBar.styles';

const ProgressBar = ({ step }: { step: number }): JSX.Element => {
  return (
    <ProgressBarStyles step={step}>
      <div></div>
    </ProgressBarStyles>
  );
};

export default ProgressBar;
