const customMediaQuery = (maxWidth: string) => `@media (max-width: ${maxWidth})`;

const size = {
  mobileS: '320px',
  mobileM: '370px',
  mobileL: '425px',
  tabletS: '540px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const media = {
  custom: customMediaQuery,
  desktop: customMediaQuery(size.desktop),
  tabletS: customMediaQuery(size.tabletS),
  tablet: customMediaQuery(size.tablet),
  laptop: customMediaQuery(size.laptop),
  laptopL: customMediaQuery(size.laptopL),
  mobileS: customMediaQuery(size.mobileS),
  mobileM: customMediaQuery(size.mobileM),
  mobileL: customMediaQuery(size.mobileL),
};
