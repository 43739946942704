import React from 'react';

// Styles
import { StandardButtonStyles } from './StandardButton.styles';

const StandardButton = ({
  value,
  onClick,
  name,
}: {
  value: string;
  onClick: () => void;
  name: string;
}): JSX.Element => {
  return (
    <StandardButtonStyles type="button" onClick={onClick} name={name}>
      {value}
    </StandardButtonStyles>
  );
};

export default StandardButton;
