import styled from 'styled-components';

export const HeaderStyles = styled.div`
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70px;
`;

export const LogoStyles = styled.img`
  height: 30px;
  padding: 35px 0px 0px 35px;
`;
